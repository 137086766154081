/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";

// eslint-disable-next-line import/no-unresolved
import { convertToLocale } from "@cashier/cashier-common";

// eslint-disable-next-line import/extensions
import { environment } from "./environments/environment";

const init = () => {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);

  const lng = convertToLocale(params.get('language'));
  const country = params.get('country') ?? '';
  const merchantId = params.get('merchantId');
  const userId = params.get('userId');
  const sessionId = params.get('sessionId');

  if ([lng, merchantId, userId, sessionId].every(parameter => Boolean(parameter))) {
    i18n
      .use(initReactI18next)
      .use(resourcesToBackend((language, namespace, callback) => {
        const token = btoa(`${merchantId}:${userId}:${sessionId}`);

        fetch(`${environment.paymentGatewayApiUrl}/api/v1/cashier/translations?country=${country}`, {
          headers: {
            'Pgw-Authorization': token,
            'Accept-Language': convertToLocale(language),
          },
        }).then(resp => resp.json()).then(resp => {
          callback(null, resp.data)
        })
      }))
      .init({
        lng,
        fallbackLng: lng,
        load: 'currentOnly',
        keySeparator: false,
        namespaceSeparator: false
      });
  }
}

export { i18n, init };
