import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AMOUNT_FIELD_NAME, CREDITCARD_CATEGORY_NAME } from '../constants';
import { getCookie, QaToolKitOptions } from '../helpers';
import { TransactionType } from '../interfaces';
import { FieldType } from '../models';
import { selectActiveInputs, selectFieldsByType } from '../selectors';
import {
  FieldsByTypeInterface,
  getCertainPaymentMethodStart,
  hideTransactionButtonSelector,
  initiateProcessing,
  PaymentMethod,
  PaymentMethodInput,
  ProcessPaymentResponse,
  resetPaymentMethod,
  selectAccountCreateInProgress,
  selectAmount,
  selectCurrencySymbol,
  selectPaymentFormActivated,
  selectPaymentInputs,
  selectPaymentMethod,
  selectPaymentMethodSelectedManually,
  selectPaymentMethodsLoading,
  selectProcessingLoading,
  selectProcessingResponse,
  selectRegistrationRequired,
  setAmount,
  setPaymentInputs,
  setPgwError,
} from '../slices';
import { useAmountValidationErrors } from './useAmountValidationErrors';
import { useCashierArgs } from './useCashierArgs';

interface UsePaymentFormProps {
  total: number;
  areInputsValid: boolean;
  customInputChange: (
    name: string,
    value: string,
    isValid: boolean,
    isFocused: boolean,
  ) => void;
  updateAmount: (
    name: string,
    value: string,
    isValid?: boolean,
    isFocused?: boolean,
  ) => void;
  favoriteAmountClickHandler: (amount: string | number) => void;
  favoriteAmountKeyboardHandler: (
    event: SyntheticEvent,
    amount: number,
  ) => void;
  agreeCheckboxClick: () => void;
  agree: boolean;
  paymentInputs: Record<string, PaymentMethodInput>;
  isCheckboxVisible: boolean;
  isButtonDisabled: boolean;
  areFavoriteAmountsVisible: boolean;
  isAccountCreateInProgress: boolean;
  isVisible: boolean;
  resetValues: boolean;
  isActivated: boolean;
  isLoaderActive: boolean;
  arePaymentMethodsLoading: boolean;
  userAmount: string;
  paymentMethod: PaymentMethod;
  activeType: string;
  activeInputs: PaymentMethodInput[];
  amountValidationErrors: string[];
  processingResponse: ProcessPaymentResponse;
  resetPaymentMethod: () => void;
  initiateProcessing: () => void;
  currency: string;
  hideTransactionButton: boolean;
  currentFields: FieldsByTypeInterface[];
  isRegistrationRequired: boolean;
  fetchCertainPaymentMethod: (
    type: TransactionType,
    methodId: string,
    bonusCode: string,
  ) => void;
}

export const usePaymentForm = (): UsePaymentFormProps => {
  const isProcessingLoading = useSelector(selectProcessingLoading);
  const userAmount = useSelector(selectAmount);
  const processingResponse = useSelector(selectProcessingResponse);
  const paymentMethod = useSelector(selectPaymentMethod);
  const activeInputs = useSelector(selectActiveInputs);
  const paymentInputs = useSelector(selectPaymentInputs);
  const isAccountCreateInProgress = useSelector(selectAccountCreateInProgress);
  const isPaymentFormActivated = useSelector(selectPaymentFormActivated);
  const isFinalScreenActivated = useSelector(selectPaymentFormActivated);
  const isPaymentMethodSelectedManually = useSelector(
    selectPaymentMethodSelectedManually,
  );
  const arePaymentMethodsLoading = useSelector(selectPaymentMethodsLoading);
  const currency = useSelector(selectCurrencySymbol);
  const hideTransactionButton = useSelector(hideTransactionButtonSelector);
  const currentFields = useSelector(selectFieldsByType);
  const isRegistrationRequired = useSelector(selectRegistrationRequired);

  const amountValidationErrors = useAmountValidationErrors();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isValidationDisabled =
    getCookie(QaToolKitOptions.disableValidation) === 'true';

  const inputErrorMsgMap = {
    [FieldType.Cvv]: t('cvvValidationFailed.message'),
    [FieldType.DateTime]: t('dateFormatValidationFailed.message'),
    [FieldType.Email]: t('emailValidationFailed.message'),
    [FieldType.CreditCardVisa]: t('cardNumberValidationFailed.message'),
    [FieldType.CreditCardMastercard]: t('cardNumberValidationFailed.message'),
    [FieldType.CreditCardNumber]: t('cardNumberValidationFailed.message'),
    [FieldType.IdentificationNumber]: t('rucIdValidationFailed.message'),
  };

  const noCheckboxMethodIds = React.useMemo(() => {
    return [
      'payment-iq-cryptocurrency-quickbit',
      'payment-iq-webredirect-flykk',
      'SwitchPaymentCard',
    ];
  }, []);

  const [total, setTotal] = useState<number>(0);
  const [agree, setAgree] = useState<boolean>(false);
  const [areInputsValid, setInputsValid] = useState<boolean>(false);
  const [stateValues, setStateValues] = useState({});
  const {
    cashierArgs: { amount: platformAmount, activeType },
  } = useCashierArgs();

  const amount = userAmount || platformAmount;

  const { id, hideAmount, categories, ruleParams } = paymentMethod;
  const { amounts } = ruleParams || {};

  useEffect(() => {
    setStateValues({});
    setAgree(false);
  }, [paymentMethod]);

  useEffect(() => {
    if (activeInputs) {
      const valuesSet = {};
      activeInputs.forEach((properties) => {
        if (properties.type === FieldType.Select) {
          if (properties.params?.options.length === 1) {
            valuesSet[properties.name] = {
              ...properties,
              isValid: true,
              value: properties.params.options[0].value,
            };
          } else {
            valuesSet[properties.name] = {
              ...properties,
              isValid: false,
              value: null,
            };
          }
        } else if (properties.name === AMOUNT_FIELD_NAME) {
          valuesSet[properties.name] = {
            ...paymentInputs.amount,
            value: amount,
          };
        } else {
          valuesSet[properties.name] = { ...properties, isValid: false };
        }
      });
      setStateValues(valuesSet);
    }
  }, [activeInputs]);

  React.useEffect(() => {
    dispatch(setPaymentInputs(stateValues));
  }, [stateValues, dispatch]);

  useEffect(() => {
    const x = Object.values(stateValues).find(({ isValid, required, name }) => {
      if (!required || name === AMOUNT_FIELD_NAME || name === 'accountId')
        return false;

      return !isValid;
    });

    setInputsValid(!x);
  }, [stateValues]);

  useEffect(() => {
    if (!stateValues[AMOUNT_FIELD_NAME]) return;

    const floatAmount = parseFloat(stateValues[AMOUNT_FIELD_NAME].value);
    floatAmount > 0 ? setTotal(floatAmount) : setTotal(0);
  }, [stateValues]);

  const customInputChange = (name, value, isValid, isFocused = true) => {
    const paymentMethodInput = activeInputs.find(
      ({ name: inputName }) => inputName === name,
    );

    if (paymentMethodInput) {
      if (processingResponse?.errors && processingResponse?.errors[name]) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { [name]: key, ...rest } = processingResponse?.errors;
        dispatch(setPgwError(rest || {}));
      }

      // add function to modify mask depending on value
      setStateValues((old) => ({
        ...old,
        [name]: {
          ...paymentMethodInput,
          value: value,
          isValid: isValidationDisabled ? true : isValid,
          errorMsg: value && inputErrorMsgMap[paymentMethodInput.type],
          isFocused,
        },
      }));
    }
  };

  const updateAmount = (_, newAmount, isValid, isFocused) => {
    const paymentMethodInput = activeInputs.find(
      ({ name: inputName }) => inputName === AMOUNT_FIELD_NAME,
    );

    setStateValues((old) => ({
      ...old,
      [AMOUNT_FIELD_NAME]: {
        ...paymentMethodInput,
        value: newAmount.toString(),
        isValid: isValidationDisabled ? true : isValid,
        errorMsg: null,
        isFocused,
      },
    }));

    dispatch(setAmount(newAmount.toString()));
  };

  const favoriteAmountClickHandler = (favoriteAmount) => {
    updateAmount(AMOUNT_FIELD_NAME, favoriteAmount.toString(), false, false);
  };

  const favoriteAmountKeyboardHandler = (event, favoriteAmount) => {
    if (event.keyCode === 13) {
      updateAmount(AMOUNT_FIELD_NAME, favoriteAmount.toString(), false, false);
    }
  };

  const toggleAgree = () => {
    setAgree(!agree);
  };

  const isCheckboxVisible = useMemo((): boolean => {
    return (
      !hideAmount &&
      categories.some(
        (category) => category.name === CREDITCARD_CATEGORY_NAME,
      ) &&
      !noCheckboxMethodIds.includes(id) &&
      activeType === TransactionType.Deposit
    );
  }, [id, hideAmount, activeType, categories]);

  const isButtonDisabled = React.useMemo(() => {
    const { id, hideAmount } = paymentMethod;
    const { amount: amountField = null as PaymentMethodInput } = paymentInputs;

    if (getCookie(QaToolKitOptions.disableValidation) === 'true') return false;
    if (hideAmount && areInputsValid) return false;
    if (null !== amountField && !amount) return true;

    return (
      !id ||
      isProcessingLoading ||
      !areInputsValid ||
      !!amountValidationErrors.length ||
      (categories.some(
        (category) => category.name === CREDITCARD_CATEGORY_NAME,
      ) &&
        !noCheckboxMethodIds.includes(id) &&
        activeType === TransactionType.Deposit &&
        !agree)
    );
  }, [
    amount,
    paymentMethod,
    paymentInputs,
    isProcessingLoading,
    areInputsValid,
    amountValidationErrors,
    noCheckboxMethodIds,
    agree,
    activeType,
    categories,
  ]);

  const areFavoriteAmountsVisible = useMemo((): boolean => {
    return (
      activeType === TransactionType.Deposit &&
      amounts.length > 0 &&
      !hideAmount &&
      !platformAmount
    );
  }, [activeType, amounts, hideAmount, platformAmount]);

  const initiateProcessingHandler = useCallback(() => {
    dispatch(initiateProcessing());
  }, [dispatch]);

  const resetPaymentMethodHandler = useCallback(() => {
    dispatch(resetPaymentMethod());
  }, [dispatch]);

  const fetchCertainPaymentMethod = useCallback(
    (
      transactionType: TransactionType,
      paymentMethodId: string,
      bonusCode?: string,
    ) => {
      dispatch(
        getCertainPaymentMethodStart({
          transactionType,
          paymentMethodId,
          bonusCode,
        }),
      );
    },
    [dispatch],
  );

  return {
    total,
    areInputsValid,
    customInputChange,
    updateAmount,
    favoriteAmountClickHandler,
    favoriteAmountKeyboardHandler,
    agreeCheckboxClick: toggleAgree,
    agree,
    paymentInputs,
    isCheckboxVisible,
    isButtonDisabled,
    areFavoriteAmountsVisible,
    isAccountCreateInProgress,
    isVisible: isPaymentFormActivated,
    resetValues: isFinalScreenActivated,
    isActivated: isPaymentMethodSelectedManually,
    isLoaderActive: isProcessingLoading,
    arePaymentMethodsLoading,
    userAmount,
    paymentMethod,
    activeType,
    activeInputs,
    amountValidationErrors,
    processingResponse,
    resetPaymentMethod: resetPaymentMethodHandler,
    initiateProcessing: initiateProcessingHandler,
    currency,
    hideTransactionButton,
    currentFields,
    isRegistrationRequired,
    fetchCertainPaymentMethod,
  };
};
