import { AMOUNT_FIELD_NAME } from '../constants';
import {
  isFinalScreenResolved,
  toNonBreakingString,
  WindowHelper,
} from '../helpers';
import {
  CashierCommunicationTasks,
  ContainerTypes,
  executeTask,
} from '../models';
import { selectProcessingAttributes } from '../selectors';
import {
  DynamicScreens,
  getCertainPaymentMethodFailure,
  getCertainPaymentMethodSuccess,
  getPaymentInfoSuccess,
  initiateProcessing,
  processPaymentFailure,
  processPaymentStart,
  processPaymentSuccess,
  registerPaymentStart,
  registrationPaymentFailure,
  registrationPaymentSuccess,
  resetConfirmationFormData,
  selectWindowFlow,
  setAccounts,
  setActiveScreen,
  setConfirmationFormData,
  setPgwError,
  toInitialPaymentInfoState,
} from '../slices';

export const transactionProcessingMiddleware =
  (store) => (next) => (action) => {
    const { paymentMethods, cashier_config, customerInformation, accounts } =
      store.getState();
    const {
      paymentMethod: {
        vendor,
        id: paymentMethodId,
        referenceId,
        source,
        hideAmount,
      },
      registrationRequired,
    } = paymentMethods;

    const { activeAccountOption } = accounts;
    const { activeScreen, bonusCode } = customerInformation;
    const { merchantId, userId, sessionId, activeType, language, hostUrl } =
      cashier_config;

    const result = next(action);

    if (action.type === initiateProcessing.toString()) {
      const paymentAttributes = selectProcessingAttributes(store.getState());

      const eventByType = {
        deposit: 'deposit_form_submit',
        withdrawal: 'withdraw_form_submit',
      };

      executeTask(CashierCommunicationTasks.paymentInitiated, {
        event: eventByType[activeType],
        value: paymentAttributes.amount,
        payment_method: paymentMethodId,
        currency: cashier_config.currency,
      });

      if (hideAmount) {
        paymentAttributes[AMOUNT_FIELD_NAME] = '0';
      }

      const callbackParameterBag = {
        userId,
        sessionId,
        merchantId,
        language,
        host: hostUrl,
      };

      store.dispatch(toInitialPaymentInfoState());

      if (registrationRequired) {
        store.dispatch(
          registerPaymentStart({
            referenceId,
            type: activeType,
            walletId: userId,
            sessionId,
            merchantId,
            registration: {
              accountId: activeAccountOption?.value || null,
              methodId: paymentMethodId,
              attributes: {
                ...paymentAttributes,
                amount: toNonBreakingString(paymentAttributes.amount),
                bonusCode,
              },
            },
          }),
        );
      } else {
        store.dispatch(
          processPaymentStart({
            paymentMethodId,
            vendor,
            merchantId,
            paymentType: activeType,
            userId,
            sessionId,
            accountId: activeAccountOption?.value || null,
            paymentAttributes: {
              ...paymentAttributes,
              amount: toNonBreakingString(paymentAttributes.amount),
              bonusCode,
            },
            callbackParameterBag,
            referenceId,
            source,
          }),
        );
      }
    }

    if (action.type === processPaymentSuccess.toString()) {
      const {
        transactionId,
        transactionState: internalTransactionState,
        status: externalTransactionState,
        paymentAttributes,
        redirectOutput,
        resultAction,
      } = action.payload || {};

      store.dispatch(
        getPaymentInfoSuccess({
          transactionId,
          state: internalTransactionState || externalTransactionState,
        }),
      );

      if (paymentAttributes && paymentAttributes.length > 0) {
        store.dispatch(
          setConfirmationFormData({ transactionId, paymentAttributes }),
        );
        store.dispatch(setActiveScreen(DynamicScreens.confirmationForm));
      }

      /*@TODO: map response to single state parameter*/
      if (redirectOutput || resultAction) {
        store.dispatch(setActiveScreen(DynamicScreens.processingResult));
      }
    }

    if (
      [
        registrationPaymentFailure.toString(),
        registrationPaymentSuccess.toString(),
      ].includes(action.type)
    ) {
      const { redirectOutput, resultAction } = action.payload || {};

      if (redirectOutput || resultAction) {
        store.dispatch(setActiveScreen(DynamicScreens.processingResult));
      }

      if (isFinalScreenResolved(action.payload)) {
        store.dispatch(setActiveScreen(DynamicScreens.finalScreen));
      }
    }

    if (
      [registrationPaymentFailure.type, processPaymentFailure.type].includes(
        action.type,
      )
    ) {
      const windowExist = selectWindowFlow(store.getState());

      if (windowExist && WindowHelper.get(ContainerTypes.Window)) {
        WindowHelper.close(ContainerTypes.Window);
      }
    }

    if (
      [
        processPaymentSuccess.toString(),
        processPaymentFailure.toString(),
      ].includes(action.type)
    ) {
      if (action.payload.response?.data || action.payload.response?.errors) {
        store.dispatch(
          setPgwError(
            action.payload.response?.data || action.payload.response?.errors,
          ),
        );
      }

      if (isFinalScreenResolved(action.payload)) {
        if (activeScreen === DynamicScreens.confirmationForm) {
          store.dispatch(resetConfirmationFormData());
        }

        executeTask(CashierCommunicationTasks.scrollTop);

        const windowExist = selectWindowFlow(store.getState());
        if (windowExist && WindowHelper.get(ContainerTypes.Window)) {
          WindowHelper.close(ContainerTypes.Window);
        }
        store.dispatch(setActiveScreen(DynamicScreens.finalScreen));
      }
    }

    if (action.type === getCertainPaymentMethodSuccess.toString()) {
      const { method } = action.payload;
      store.dispatch(setAccounts(action.payload));

      if (
        !method.description &&
        !method.inputs.some((input) => input.required)
      ) {
        store.dispatch(initiateProcessing());
      }
    }

    if (action.type === getCertainPaymentMethodFailure.toString()) {
      const { status } = action.payload;

      if (status === 404) {
        store.dispatch(setActiveScreen(DynamicScreens.finalScreen));
      }
    }

    return result;
  };
