import React from 'react';
import { createRoot } from 'react-dom/client';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { SentryIgnore } from '@cashier/cashier-common';

import { App } from './app/app';
import { environment } from './environments/environment';
import { init } from './i18n';
import registerServiceWorker from './registerServiceWorker';
import { sentryReleaseId } from './sentry-release-id';

import './global.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
(window as any).paymentIqCardEncrypterUrl =
  environment.paymentIqCardEncrypterUrl;
const sampleRate = environment.sentryEnvironment === 'staging' ? 1 : 0.25;

init();

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryKey,
    environment: environment.sentryEnvironment,
    ignoreErrors: SentryIgnore,
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      Sentry.browserTracingIntegration({
        tracingOrigins: [environment.cashierUrl, 'localhost'],
      }),
    ],
    release: sentryReleaseId,
    tracesSampleRate: sampleRate,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

const isE2ETest = typeof (window as any).Cypress !== 'undefined';
if (environment.production && !isE2ETest && !(window as any).isPrerender) {
  registerServiceWorker();
}
