import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntegrationSource, TransactionType } from '../../interfaces';
import { PaymentMethodInput } from '../paymentForm';

export enum ResultContainerTypes {
  DIRECT = 'direct',
  DISPLAY = 'display',
  FORM = 'form',
  IFRAME = 'iframe',
  PARAMETERS = 'parameters',
  REDIRECT = 'redirect',
  WINDOW = 'window',
}

export interface ProcessingOutput {
  type: 'html' | 'redirect' | 'form' | 'display';
  html: string | null;
  url: string;
  method: 'POST' | 'GET';
  parameters: { [key: string]: string };
  container: string;
  height: number;
  width: number;
  output: string;
  fields: PaymentMethodInput[];
}

export interface ProcessPaymentResponse {
  transactionId: string;
  transactionState?: string; //for internal
  status?: string; //for pspi
  redirectOutput: ProcessingOutput | null;
  resultAction: ProcessingOutput | null;
  error: string;
  errors: {
    [key: string]: string[];
  };
  message: string;
  description: string;
  paymentAttributes: PaymentMethodInput[];
}

export interface ProcessPaymentRequest {
  paymentMethodId: string;
  vendor: string;
  merchantId: string;
  paymentType: TransactionType;
  userId: string;
  sessionId: string;
  paymentAttributes: { [key: string]: string };
  callbackParameterBag: { [key: string]: string };
  referenceId: string;
  source: IntegrationSource;
  accountId?: string | null;
}

export interface RegisterPaymentRequest {
  referenceId: string;
  type: TransactionType;
  walletId: string;
  sessionId: string;
  merchantId: string;
  registration: {
    accountId?: string | null;
    methodId: string;
    attributes: { [key: string]: string };
  };
}

export interface PaymentProcessingState {
  processingResponse: ProcessPaymentResponse;
  isProcessingLoading: boolean;
}

export const PAYMENT_PROCESSING_FEATURE_KEY = 'transaction_processing';

export const initialPaymentProcessingState: PaymentProcessingState = {
  processingResponse: {
    status: '',
    resultAction: null,
    redirectOutput: null,
    message: '',
    error: '',
    errors: null,
    description: '',
    transactionId: '',
    paymentAttributes: [],
  },
  isProcessingLoading: false,
};

export const paymentProcessingSlice = createSlice({
  name: PAYMENT_PROCESSING_FEATURE_KEY,
  initialState: initialPaymentProcessingState,
  reducers: {
    resetStore: (state) => {
      state.processingResponse = {
        ...initialPaymentProcessingState.processingResponse,
      };
      state.isProcessingLoading = false;
    },
    initiateProcessing: (state) => {},
    processPaymentStart: (
      state,
      action: PayloadAction<ProcessPaymentRequest>,
    ) => {
      state.isProcessingLoading = true;
    },
    processPaymentSuccess: (
      state,
      action: PayloadAction<ProcessPaymentResponse>,
    ) => {
      state.processingResponse = action.payload;
      state.isProcessingLoading = false;
    },
    processPaymentFailure: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.processingResponse = action.payload.response || action.payload;
        state.isProcessingLoading = false;
      }
    },
    setPgwError: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.processingResponse.errors = action.payload;
      }
    },
  },
});

export const paymentProcessingReducer = paymentProcessingSlice.reducer;

export const {
  initiateProcessing,
  processPaymentStart,
  processPaymentSuccess,
  processPaymentFailure,
  setPgwError,
  resetStore,
} = paymentProcessingSlice.actions;
