import { createSelector } from '@reduxjs/toolkit';

import { componentsName } from '../constants';
import { FieldType } from '../models';
import { FieldsByTypeInterface, selectPaymentMethod } from '../slices';
import { selectActiveInputs } from './activeInputs';

const paymentIdDirecta = 'payment-iq-bank-directa24';
const paymentIdBankTransfer = 'payment-iq-banklocal-banktransfercan';

const numberFieldsException = (type: string) => {
  return [
    FieldType.Amount,
    FieldType.Cvv,
    FieldType.CardCvc,
    FieldType.AccountId,
  ];
};

const excludedInputTypes = (type: string) => {
  return [
    FieldType.DateTime,
    FieldType.Cvv,
    FieldType.CardCvc,
    FieldType.Number,
    FieldType.DynamicSelect,
    FieldType.Select,
  ].some((item) => item === type);
};

export const selectFieldsByType = createSelector(
  selectActiveInputs,
  selectPaymentMethod,
  (s, { id }): FieldsByTypeInterface[] => {
    const cardInfo = {} as { cvv?: string; date_picker?: number };

    return s.reduce((acc, input) => {
      const { type, name } = input;

      if (
        (type === FieldType.Number || type === FieldType.Integer) &&
        name === FieldType.Amount
      ) {
        acc.push({ ...input, componentName: componentsName.amount_input });
      }

      if (
        (type === FieldType.DynamicSelect || type === FieldType.Select) &&
        name === FieldType.Amount
      ) {
        acc.push({ ...input, componentName: componentsName.amount_select });
      }

      if (
        type === FieldType.DynamicSelect &&
        [paymentIdBankTransfer, paymentIdDirecta].includes(id)
      ) {
        acc.push({ ...input, componentName: componentsName.dynamic_select });
      }

      if (type === FieldType.Number && name !== FieldType.Amount) {
        acc.push({ ...input, componentName: componentsName.input });
      }

      if (
        (type === FieldType.DynamicSelect || type === FieldType.Select) &&
        ![paymentIdBankTransfer, paymentIdDirecta].includes(id) &&
        name !== FieldType.Amount
      ) {
        acc.push({ ...input, componentName: componentsName.select });
      }

      if (type === FieldType.DateTime || name === FieldType.ExpirationDate) {
        if (cardInfo?.cvv) {
          acc[cardInfo.cvv] = {
            ...acc[cardInfo.cvv],
            [name]: { ...input, componentName: componentsName.date_picker },
          };
        } else {
          acc.push({
            [name]: { ...input, componentName: componentsName.date_picker },
          });
          cardInfo.date_picker = acc.length - 1;
        }
      }

      if (type === FieldType.Cvv || type === FieldType.CardCvc) {
        if (cardInfo?.date_picker) {
          acc[cardInfo.date_picker] = {
            ...acc[cardInfo.date_picker],
            [name]: { ...input, componentName: componentsName.cvv },
          };
        } else {
          acc.push({ [name]: { ...input, componentName: componentsName.cvv } });
          cardInfo[name] = acc.length - 1;
        }
      }

      if (type === FieldType.Number && !numberFieldsException(name)) {
        acc.push({ ...input, componentName: componentsName.input });
      }

      if (
        !excludedInputTypes(type) &&
        name !== FieldType.AccountId &&
        name !== FieldType.Amount &&
        name !== FieldType.ExpirationDate
      ) {
        acc.push({ ...input, componentName: componentsName.text_field });
      }

      return acc;
    }, []);
  },
);
